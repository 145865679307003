import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';

import DashboardMessenger from '@avira-pwm/messenger/dashboard';

import { usePrevious } from '../../lib/hooks';
import { RootState } from '../../app/store';
import { sendSync } from '../AuthenticationActions';

interface AuthData {
  email: string | null;
  auth_token: string | null;
  distinct_id: string | null;
  expires_at: number | null;
  expires_in: number | null;
  key: string | null;
  key2: string | null;
  oe_token: string | null;
  oeUserId: string | null;
  refresh_token: string | null;
  timestamp: number | null;
  lockReason: string | null;
  vaultEncryptionKey?: string | null;
  vaultChallengeKey?: string | null;

  // optional as old versions won't send this
  bypassVault?: string | null;
}

export interface ReceiveAuthData extends AuthData {
  user_id: string | null;
  tempKey: string | null;
  isUnregisteredMode: boolean | null;
  tempUserId: string | null;
  tempOeToken: string | null;
  tempOeRefreshToken: string | null;
  tempExpiresAt: number | null;
  tempExpiresIn: number | null;
  lockReason: string | null;
}

interface ReceiveAutolock {
  value: number | null;
  timestamp: number | null;
}

export type StateProps = Pick<
RootState,
'oe' | 'auth' | 'user' | 'tracking' | 'nlokData'
>;

export interface DispatchProps {
  onDataReceived: (data?: ReceiveAuthData) => (Promise<void> | void);
  onConnect: () => (Promise<void> | void);
  onDisconnect: () => void;
  setLocalSalt: (salt: string) => void;
  setExtensionAutolock: (data: any) => void;
}

export type OwnProps = PropsWithChildren<{
  messenger: DashboardMessenger;
  renderWhileLoading: () => React.ReactNode;
}>;

export type Props = OwnProps & StateProps & DispatchProps;

const ExtensionSync = ({
  messenger,
  onConnect,
  onDisconnect,
  onDataReceived,
  setLocalSalt,
  setExtensionAutolock,
  renderWhileLoading,
  children,
  auth,
  oe,
  tracking,
  user,
  nlokData,
}: Props): JSX.Element => {
  const [loaded, setLoaded] = useState(false);
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const removeListener = messenger.on('extension:dashboard:setAuthData', (data: ReceiveAuthData) => {
      onDataReceived(data);
      setLoaded(true);
    });

    return (): void => { removeListener(); };
  }, [messenger, onDataReceived]);

  useEffect(() => {
    const removeConnectListener = messenger.on('connected', async () => {
      setConnected(true);
    });

    const removeDisconnectListener = messenger.on('disconnect', () => {
      setConnected(false);
      setLoaded(true);
    });

    async function connectMessenger(): Promise<void> {
      const messengerConnected = await messenger.connect();
      setConnected(messengerConnected);
      if (!messengerConnected) {
        setLoaded(true);
      }
    }

    connectMessenger();

    return (): void => {
      removeConnectListener();
      removeDisconnectListener();
    };
  }, [messenger]);

  useEffect(() => {
    async function connectedEffect(): Promise<void> {
      messenger.send('dashboard:extension:getAuthData');
      messenger.send('dashboard:extension:getLocalSalt', null, (_err, localSalt: string) => {
        dispatch(setLocalSalt(localSalt));
      });

      await onConnect();
    }

    if (connected) {
      connectedEffect();
    } else {
      onDisconnect();
    }
  }, [connected, messenger, onConnect, onDisconnect, dispatch, setLocalSalt]);

  useEffect(() => {
    const removeListener = messenger.on('extension:dashboard:setAutolock', (data: ReceiveAutolock) => {
      setExtensionAutolock(data);
    });

    return (): void => { removeListener(); };
  }, [messenger, setExtensionAutolock]);

  const prevTimestamp = usePrevious(auth.timestamp);
  const prevConnect = usePrevious(connected);

  useEffect(() => {
    if (connected && (!prevConnect || auth.timestamp !== prevTimestamp)) {
      dispatch(sendSync(false));
    }
  }, [
    dispatch,
    messenger,
    auth.timestamp,
    prevTimestamp,
    connected,
    prevConnect,
    auth,
    oe,
    tracking,
    user,
    nlokData,
  ]);

  if (!loaded) {
    return (
      <>
        {renderWhileLoading()}
      </>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default ExtensionSync;
