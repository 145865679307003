import * as Sentry from '@sentry/browser';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { History } from 'history';
import omit from 'lodash/omit';

import { getServerTimeFromErrorMsg } from '@avira-pwm/helpers/ServerDate';
import DashboardMessenger from '@avira-pwm/messenger/dashboard';
import NotificationServer from '@avira-pwm/notification';
import { ModelNames } from '@avira-pwm/sync/ModelMerge';

import compareVersions from 'compare-versions';
import { ModelCrypto } from '@avira-pwm/sync';
import * as awsHelpers from '@avira-pwm/sync/helpers/aws';
import * as nlokFileHelpers from '@avira-pwm/sync/helpers/file/nlok';
import { KeyDBStorage } from '@avira-pwm/storage';
import { sha256 } from '@avira-pwm/crypto-tools';
import {
  DashboardActionTypes,
  DASHBOARD_SET_EXTENSION_CONNECTED,
  DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED,
  DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED,
  DASHBOARD_SET_SYNC_DATA,
  DASHBOARD_ACTIVATE_UNREGISTERED_MODE,
  DASHBOARD_DEACTIVATE_UNREGISTERED_MODE,
  DASHBOARD_DISMISS_CONGRATS_REGISTER,
  DASHBOARD_SET_EXTENSION_DISCONNECTED,
  DASHBOARD_SET_ERROR,
  DASHBOARD_SET_LOADING_OVERLAY,
  DASHBOARD_CLEAR_LOADING_OVERLAY,
  DASHBOARD_SET_SCROLLING,
  DashboardError,
  DASHBOARD_SET_S3_FILE_AUTH_DATA,
  DASHBOARD_CLEAR_ERROR,
} from './DashboardActionTypes';

import { TrackingActions, MixpanelEvents } from '../tracking';
import { syncGetAccounts, clearAccounts } from '../accounts/AccountActions';
import { syncGetNotes, clearNotes } from '../notes/NoteActions';
import { syncGetFiles, clearFiles } from '../files/FileActions';
import { clearHibpData } from '../securityStatus/HIBPActions';
import { syncGetPasswordsBreaches, clearPasswordsBreaches } from '../securityStatus/PasswordsBreachesActions';
import {
  syncGetDeletedUnknownBreaches, clearDeletedUnknownBreaches,
} from '../securityStatus/DeletedUnknownBreachesActions';

import config from '../config';
import mixpanel from '../tracking/mixpanel';
import { getRelevantUserKey, getRelevantUserId } from '../user/selectors';
import { getBrowser, isInternetExplorer } from '../lib/UserAgent';
import { syncGetCreditCards, clearWallet } from '../wallet/WalletActions';

import { ThunkExtraArgument } from '../app/thunk';
import { RootState } from '../app/store';
import asyncJsonStorage from '../lib/asyncJsonStorage';
import jwtDecode from '../lib/JWTDecoder';
import debug from '../debug';
import { getServerTimeOffset, setServerTime } from '../preferences/helpers';
import { setServerTimeOffset } from '../preferences/PreferencesActions';
import { handleSyncError } from '../lib/ErrorHelper';
import { shouldConnectToNDSAdapter } from '../nlok/selectors';

type DashboardThunkAction<R, A extends AnyAction = AnyAction> = (
  ThunkAction<R, RootState, ThunkExtraArgument, A>
);

const log = debug.extend('DashboardActions');

const { trackEvent } = TrackingActions;
const {
  MP_GETPRO_CLICKED, MP_DELETE_EXTENSION_CLICKED, MP_WHY_REGISTER_SHOWN, MP_WHY_DO_I_SEE_THIS_MORE,
} = MixpanelEvents;

const modelActionsMap = {
  Account: {
    get: syncGetAccounts,
    clear: clearAccounts,
  },
  Note: {
    get: syncGetNotes,
    clear: clearNotes,
  },
  PasswordBreaches: {
    get: syncGetPasswordsBreaches,
    clear: clearPasswordsBreaches,
  },
  DeletedUnknownBreach: {
    get: syncGetDeletedUnknownBreaches,
    clear: clearDeletedUnknownBreaches,
  },
  CreditCard: {
    get: syncGetCreditCards,
    clear: clearWallet,
  },
  File: {
    get: syncGetFiles,
    clear: clearFiles,
  },
};

function triggerCacheCleaning(adapterName: string): void {
  if (isInternetExplorer() && adapterName === 'CognitoAdapter') {
    // IE heavily caches the cognito GET requests.
    // Cache busting does not work because cognito rejects the
    // request if it has parameters it doesn't know.
    // It's also not possible to modify the response headers on
    // cognito to tell IE not to cache those.
    // Clearing it's local cache is the only feasible solution.
    // The main problem with it is that it "clears ALL session cookies,
    // Authentication, and Client Certificates for ALL sites running in the current session".
    // References:
    //  https://stackoverflow.com/a/31121304
    //  https://blogs.msdn.microsoft.com/ieinternals/2010/04/04/understanding-session-lifetime/
    //  https://bugs.chromium.org/p/chromium/issues/detail?id=5497#c16
    //  https://docs.microsoft.com/en-us/previous-versions//ms536979(v=vs.85)?redirectedfrom=MSDN
    document.execCommand('ClearAuthenticationCache', false);
  }
}

type ExtensionInfo = {
  isOutdated: boolean;
  outdatedTimestamp: number;
  currentHash?: string;
  installedVersion?: string;
  currentVersion?: string;
};

function getExtensionInfo(dashboardMessenger: DashboardMessenger): Promise<ExtensionInfo | null> {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(async () => {
      try {
        const browser = getBrowser().toLowerCase();

        const url = config.localBuild
          ? 'http://localhost:8080'
          : 'https://s3.eu-central-1.amazonaws.com/avira-pwm-extensions';
        const response = await fetch(`${url}/ext-version-${
          browser
        }-${
          config.localBuild ? 'local' : config.environment
        }.json`);

        const { hash } = await response.json();

        resolve({
          currentHash: hash,
          isOutdated: true,
          outdatedTimestamp: 0,
        });
      } catch (e) {
        resolve(null);
      }
    }, 200);

    dashboardMessenger.send('VersionCheck:getVersionInfo', null, (err, data) => {
      clearTimeout(timeoutId);
      resolve(data);
    });
  });
}

// eslint-disable-next-line complexity
export const setExtensionConnected = (): DashboardThunkAction<Promise<void>> => async (
  dispatch, _getState, { syncAdapters, dashboardMessenger },
) => {
  const info = await getExtensionInfo(dashboardMessenger);

  mixpanel.register({ extensionConnected: true });

  dispatch({
    type: DASHBOARD_SET_EXTENSION_CONNECTED,
    value: {
      connected: true,
      compatible: await syncAdapters.extension.checkExtensionCompatibility(),
      installedVersion: (info && info.installedVersion) ?? null,
      currentVersion: (info && info.currentVersion) ?? null,
      currentHash: (info && info.currentHash) ?? null,
      isOutdated: (info && info.isOutdated) ?? null,
      outdatedTimestamp: (info && info.outdatedTimestamp) ?? null,
    },
  });
};

export const setExtensionDisconnected = (): DashboardActionTypes => ({
  type: DASHBOARD_SET_EXTENSION_DISCONNECTED,
});

export const verifyExtensionVersion = (): DashboardThunkAction<void> => (
  async (_dispatch, getState) => {
    const { dashboard: { extensionInstalledVersion, extensionConnected } } = getState();

    if (!extensionConnected) {
      return;
    }

    const versionFile: { extension?: { required?: string } } = config.versionCheck
      ? (await fetch(config.versionCheck).then(val => val.json()).catch(() => null))
      : null;

    if (versionFile?.extension?.required && extensionInstalledVersion) {
      const requiredVersion = versionFile.extension.required.replace(/(\d+\.\d+\.\d+)(\..+)?/, '$1');
      if (compareVersions(extensionInstalledVersion, requiredVersion) === -1) {
        // eslint-disable-next-line no-throw-literal
        throw 'error.outdated.extension.version';
      }
    }
  }
);

export const setExtensionAdapterConnected = (value: boolean): DashboardActionTypes => (
  { type: DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED, value }
);

export const setCloudSyncAdapterConnected = (value: boolean): DashboardActionTypes => (
  { type: DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED, value }
);

export const initializeDashboardPageNavigator = (history: History):
DashboardThunkAction<Promise<void>> => async (
  dispatch, _getState, { dashboardMessenger },
) => {
  if (dashboardMessenger.isConnected()) {
    dashboardMessenger.on('extension:dashboard:navigateToPage', (dashboardPage) => {
      if (window.location.href !== dashboardPage) {
        const urlObj = new URL(dashboardPage);
        const dashboardUrl = `${urlObj.pathname}${urlObj.search}`;
        history.push(dashboardUrl);
      }
    });
  }
};

export const enableCloudSyncAdapter = ():
DashboardThunkAction<Promise<void>> => async (dispatch, getState, { syncAdapters }) => {
  dispatch(setCloudSyncAdapterConnected(true));
  await syncAdapters.cloudSync.enable();
  if (shouldConnectToNDSAdapter(getState())) {
    await syncAdapters.nds.enable();
  }
};

export const disableCloudSyncAdapter = ():
DashboardThunkAction<void> => (dispatch, _getState, { syncAdapters }) => {
  dispatch(setCloudSyncAdapterConnected(false));
  syncAdapters.cloudSync.disable();
  syncAdapters.nds.disable();
};

export const setSyncData = (): DashboardThunkAction<Promise<void>, DashboardActionTypes> => (
  async (dispatch, getState, { licenseService }) => {
    const { user } = getState();
    const syncData = await licenseService.getSyncStoreToken(user.authToken);
    const credentials = awsHelpers.getCredentials(syncData);
    await awsHelpers.connect(credentials);
    dispatch({
      type: DASHBOARD_SET_SYNC_DATA,
      value: {
        data: syncData,
        credentials,
      },
    });
  }
);

const clearSyncData = (): DashboardActionTypes => ({
  type: DASHBOARD_SET_SYNC_DATA,
  value: { data: null, credentials: null },
});

export const setS3FileAuthData = (): DashboardThunkAction<Promise<void>, DashboardActionTypes> => (
  async (dispatch, getState, { s3FileService }) => {
    const { oe } = getState();
    if (!oe.token) {
      return;
    }

    const syncData = await s3FileService.getCredentials(oe.token);
    const credentials = nlokFileHelpers.getCredentials(syncData);
    await awsHelpers.connect(credentials);

    dispatch({
      type: DASHBOARD_SET_S3_FILE_AUTH_DATA,
      value: {
        data: syncData,
        credentials,
      },
    });
  }
);

export const clearS3FileAuthData = (): DashboardActionTypes => ({
  type: DASHBOARD_SET_S3_FILE_AUTH_DATA,
  value: { data: null, credentials: null },
});

export const disconnectCloudSyncAdapter = ():
DashboardThunkAction<Promise<void>> => async (dispatch, _getState, { syncAdapters }) => {
  dispatch(setCloudSyncAdapterConnected(false));
  syncAdapters.cloudSync.disconnect();
  syncAdapters.nds.disconnect();
};

export const connectCloudSyncAdapter = (
  (): DashboardThunkAction<Promise<void>, DashboardActionTypes> => (
    // eslint-disable-next-line max-statements
    async (dispatch, getState, { syncAdapters }) => {
      triggerCacheCleaning(syncAdapters.cloudSync.name);

      const state = getState();
      const shouldConnectToNDS = shouldConnectToNDSAdapter(state);

      const {
        dashboard: {
          syncData,
          aviraAwsCredentials: credentials,
        },
      } = state;

      if (credentials == null || syncData == null) {
        return;
      }

      try {
        await syncAdapters.cloudSync.connect({ credentials, region: syncData.region });
      } catch (e) {
        if ((e as Error).name === 'InvalidSignatureException') {
          const serverDate = getServerTimeFromErrorMsg((e as Error).message);
          if (serverDate) {
            setServerTime(serverDate);
            dispatch(setServerTimeOffset(getServerTimeOffset()) as any);
            await syncAdapters.cloudSync.connect({ credentials, region: syncData.region });
          } else {
            throw e;
          }
        } else {
          throw e;
        }
      }

      if (shouldConnectToNDS) {
        const { aguid } = jwtDecode(state.oe.token!);

        const ndsAdapterStorage = new KeyDBStorage(
          asyncJsonStorage,
          `NDS:${sha256(aguid)}`,
        );

        await syncAdapters.nds.connect({
          avira: {
            crypto: new ModelCrypto(state.user.key!),
          },
          store: ndsAdapterStorage,
        });
      }
      await dispatch(setCloudSyncAdapterConnected(true));
    }
  )
);

export const connectExtensionAdapter = ():
DashboardThunkAction<Promise<void>> => async (dispatch, getState, { syncAdapters }) => {
  // @todo change extension adapter to not require id?
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  await syncAdapters.extension.connect({ user: { key: getRelevantUserKey(getState()) } });
  dispatch(setExtensionAdapterConnected(true));
};

export const disconnectExtensionAdapter = ():
DashboardThunkAction<void> => (dispatch, getState, { syncAdapters }) => {
  syncAdapters.extension.disconnect();
  dispatch(setExtensionAdapterConnected(false));
};

export const connectAsyncStorageAdapter = ():
DashboardThunkAction<Promise<void>> => async (dispatch, getState, { syncAdapters }) => {
  await syncAdapters.asyncStorage.connect({
    user: {
      key: getRelevantUserKey(getState()),
      id: getRelevantUserId(getState()),
    },
  });
};

export const disconnectAsyncStorageAdapter = ():
DashboardThunkAction<void> => (dispatch, getState, { syncAdapters }) => {
  syncAdapters.asyncStorage.disconnect();
};

export const initializeSync = (): DashboardThunkAction<void> => (
  dispatch, getState, { syncInstance },
) => syncInstance.initialize();

export const checkIfUserHasLocalData = (): DashboardThunkAction<Promise<boolean>> => async (
  dispatch, getState, { syncAdapters: { indexedDB }, models },
) => {
  const { user, userData } = getState();
  const adapterConfig = { user: { key: user.key as string, id: userData.id as unknown as string } };

  try {
    return await indexedDB.hasData(adapterConfig, models as any);
  } catch (e) {
    return false;
  }
};

export const configureSyncModelsToMatchExtension = (
  (): DashboardThunkAction<Promise<void>> => async (
    dispatch,
    _getState,
    {
      syncInstance,
      syncAdapters: { extension },
      models: initialModels,
    },
  ) => {
    const { models } = await extension.pingExtension() ?? { models: [...initialModels] };
    syncInstance.setModels(models);
    await dispatch(setExtensionConnected());
  }
);

// eslint-disable-next-line complexity, max-statements
export const connectSync = (): DashboardThunkAction<Promise<void>> => async (dispatch, getState, {
  syncInstance,
  syncAdapters: { localStorage, indexedDB, spotlight },
  models,
}) => {
  const { user, userData } = getState();

  const adapterConfig = { user: { key: user.key as string, id: userData.id as unknown as string } };

  if (config.spotlight && config.spotlightSync) {
    log(`connecting to ${spotlight.name}`);
    await spotlight.connect(adapterConfig);
    log(`${spotlight.name} connected`);
  }

  const localStorageHasData = await localStorage.hasData(adapterConfig, models);
  if (localStorageHasData) {
    localStorage.connect(adapterConfig);
  }

  try {
    log(`connecting to ${indexedDB.name}`);
    await indexedDB.connect(adapterConfig);
    log(`${indexedDB.name} connected`);
  } catch (e) {
    if (e.message !== 'IndexedDBAdapter already connected.'
      && e.message !== 'Please provide user id to IndexedDBAdapter') {
      log(`${indexedDB.name} error`, e);
      indexedDB.disconnect();
    } else {
      throw e;
    }
  }

  syncInstance.on('signatureError', (data) => {
    dispatch(trackEvent('signatureError', omit(data, ['id'])));
  });

  syncInstance.on('error', (e) => {
    try {
      handleSyncError(e, 'errorListener');
    } catch (rethrownError) {
      Sentry.withScope((scope) => {
        scope.setExtra('context', 'errorListener');
        Sentry.captureException(rethrownError);
      });
    }
  });

  try {
    await dispatch(initializeSync());
  } catch (e) {
    handleSyncError(e, 'connectSync');
  }

  if (localStorageHasData) {
    localStorage.disconnect();
    localStorage.clearData(adapterConfig, models);
  }
};

export const disconnectSync = (): DashboardThunkAction<Promise<void>> => async (
  dispatch, _getState, { syncInstance, syncAdapters },
) => {
  syncAdapters.extension.removeAllListeners('connected');
  syncAdapters.extension.removeAllListeners('disconnected');
  syncAdapters.localStorage.disconnect();
  syncAdapters.indexedDB.disconnect();
  if (syncAdapters.spotlight.isConnected()) {
    syncAdapters.spotlight.disconnect();
  }
  dispatch(disconnectCloudSyncAdapter());
  dispatch(disconnectExtensionAdapter());
  dispatch(disconnectAsyncStorageAdapter());
  syncInstance.removeAllListeners('signatureError');
  syncInstance.removeAllListeners('error');
  syncInstance.disconnect();

  dispatch(clearSyncData());
};

export const triggerSync = (): DashboardThunkAction<void> => (
  dispatch, getState, { syncAdapters },
) => {
  if (syncAdapters.cloudSync.isConnected()) {
    triggerCacheCleaning(syncAdapters.cloudSync.name);
    syncAdapters.cloudSync.sync();
  }
};

export const connectNotificationServer = ():
DashboardThunkAction<Promise<NotificationServer>> => async (
  dispatch, getState, { notificationServer },
) => {
  const { user } = getState();
  await notificationServer.connect(user.authToken as string);
  return notificationServer;
};

export const disconnectNotificationServer = (): DashboardThunkAction<void> => (
  dispatch, getState, { notificationServer },
) => {
  notificationServer.disconnect();
  notificationServer.removeAllListeners();
};

export const syncGetData = (): DashboardThunkAction<void> => async (dispatch) => {
  Object.values(modelActionsMap).forEach((modelActions) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(modelActions.get());
  });
};

export const initSyncListeners = (): DashboardThunkAction<void> => async (
  dispatch, getState, { syncInstance },
) => {
  syncInstance.on('updated', (updates: { [K in ModelNames]: any }) => {
    Object.keys(updates).forEach((model) => {
      const modelActions = modelActionsMap[model as keyof typeof modelActionsMap];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (modelActions) dispatch(modelActions.get());
    });
  });
};

export const clearData = (): DashboardThunkAction<void> => (dispatch) => {
  Object.values(modelActionsMap).forEach((modelActions) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dispatch(modelActions.clear());
  });
  dispatch(clearHibpData());
};

export const trackGetProClicked = (context: string):
DashboardThunkAction<void> => async (dispatch) => {
  dispatch(trackEvent(MP_GETPRO_CLICKED, { context }));
};

export const deleteExtension = (): DashboardThunkAction<void> => async (
  dispatch, getState, { dashboardMessenger },
) => {
  const { preferences } = getState();
  if (dashboardMessenger.isConnected()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    dashboardMessenger.send('dashboard:extension:deleteExtension', preferences.abTestVersion);
    dispatch(trackEvent(MP_DELETE_EXTENSION_CLICKED));
  }
};

export const activateUnregisteredMode = (
  unregisteredUserData: { tempKey: string; tempUserId: string },
): DashboardThunkAction<void> => async (dispatch) => {
  mixpanel.setUnregisteredMode(true);
  dispatch({ type: DASHBOARD_ACTIVATE_UNREGISTERED_MODE, value: unregisteredUserData });
};

export const deactivateUnregisteredMode = (): DashboardThunkAction<void> => async (dispatch) => {
  mixpanel.setUnregisteredMode(false);
  dispatch({ type: DASHBOARD_DEACTIVATE_UNREGISTERED_MODE });
};

export const dismissCongratsOnRegistration = (): DashboardActionTypes => (
  { type: DASHBOARD_DISMISS_CONGRATS_REGISTER }
);

export const trackWhyRegisterShown = (dashboardSource?: string):
DashboardThunkAction<void> => async (dispatch) => {
  if (dashboardSource) {
    mixpanel.register({
      DashboardSource: dashboardSource,
    });
  }
  dispatch(trackEvent(MP_WHY_REGISTER_SHOWN));
};

export const doNotShowSaveNotification = (): DashboardThunkAction<void> => async (
  dispatch, getState, { dashboardMessenger },
) => {
  if (dashboardMessenger.isConnected()) {
    dashboardMessenger.send('dashboard:extension:neverShowSaveNotification');
  }
};

export const trackWhyDoISeeThisTellMeMore = (): DashboardThunkAction<void> => (dispatch) => {
  dispatch(trackEvent(MP_WHY_DO_I_SEE_THIS_MORE));
};

export const setError = (value: DashboardError): DashboardActionTypes => ({
  type: DASHBOARD_SET_ERROR, value,
});

export const clearError = (): DashboardActionTypes => ({
  type: DASHBOARD_CLEAR_ERROR,
});

export const setLoadingOverlay = (
  (message: string, timeout: number | null): DashboardActionTypes => ({
    type: DASHBOARD_SET_LOADING_OVERLAY, value: { message, timeout },
  })
);

export const clearLoadingOverlay = (): DashboardActionTypes => ({
  type: DASHBOARD_CLEAR_LOADING_OVERLAY,
});

export const setScrolling = (scrolling: boolean): DashboardActionTypes => ({
  type: DASHBOARD_SET_SCROLLING, scrolling,
});
